/* src/pages/Home/home.module.css */

.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
.container {
  height: 65vh;
}
.sectionsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px; /* Espacio entre las secciones */
  padding: 20px;
}
.highlightContentCard {
  text-align: center;
  padding-top: 20px;
}

.highlightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 0px;
  gap: 80px;
}

.highlightImage {
  width: 40%; /* Ajusta esto según el tamaño deseado de la imagen */
  height: auto;
}

.highlightContent {
  width: 40%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 30px 0px;
}
.highlightTitles {
  font-size: clamp(25px, 4vw, 31px);
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}
.highlightTitle {
  font-size: clamp(20px, 4vw, 40px);
  color: #000;
}

.highlightDescription {
  padding: 0px 150px;
  color: #686868;
  text-align: justify;
  margin-block-start: 0;
  margin-block-end: 0;
}
.highlight {
  color: #686868;
  text-align: center;
  font-weight: bold;
  width: 90%;
}
.highlightButton {
  padding: 10px 30px;
  border: 2px solid transparent; /* Establece un borde transparente que se hará visible en hover */
  color: white;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  background-color: #e30613; /* Rojo como color de fondo normal */
  transition: background-color 0.3s, border-color 0.3s; /* Añade transición para el color de borde también */
}
.highlightButton:hover {
  background-color: #ffff;
  border-color: #e30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #e30613; /* Igualmente, haz visible el borde en hover */
}
/* Asegúrate de agregar media queries para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 788px) {
  .sectionsContainer {
    gap: 20px; /* Espacio entre las secciones */
  }
}
@media (max-width: 768px) {

  .highlightSection {
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }
  .highlightImage {
    width: 45%;
  }

  .highlightDescription {
    padding: 20px;
  }
  .highlight {
    text-align: center;
  }
}
.termsSection {
  filter: brightness(121%);
  display: flex;
  justify-content: center;
  padding: 30px 100px;
  gap: 20px;
}

.termsColumn {
  flex: 1;
  padding: 0px 0px 0px 20px;
  border-left: 5px solid #5f5f5f;
  text-align: center;
}

/* Remueve el borde izquierdo del primer elemento */
.termsColumn:first-child {
  border-left: none;
}

.termsTitle {
  margin-block-start: 0;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: clamp(20px, 4vw, 22px);
}

.termsItem {
  margin-bottom: 10px;
  font-size: clamp(16px, 3vw, 18px);
  text-decoration: none;
  color: black;
}
.termsItem:hover {
  color: #E30613;
}

/* Asegúrate de tener media queries para el diseño responsivo si es necesario */
@media (max-width: 768px) {
  .highlightContent {
    width: 100% !important;
  }
  .termsSection {
    flex-direction: column;
    align-items: center;
    gap: 0px;
    padding: 20px 40px;
  }

  .termsColumn {
    padding: 20px 0px 0px 0px;
    border-left: none;
    border-top: 5px solid #333;
    width: 100%;
  }

  .termsColumn:first-child {
    border-top: none;
  }
}
@media (max-width: 930px) {
  .highlightContent {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .highlightImage {
    width: 55%;
    max-width: 80%;
    min-width: 75%;
  }
}
