/* Estilos generales */

.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
/* Estilos generales */
.carouselContainer {
  width: 100%;
  height: 400px; 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.carouselContent {
  color: white;
  flex: 2; /* Ajusta la proporción del texto e imagen */
  padding: 50px; /* Ajusta el padding general */
}

.imageSection {
  flex: 2; /* Proporción ajustada para la imagen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageSection img {
  max-width: 100%;
  height: auto;
}

.carouselTitle {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.carouselSubtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  padding-right: 340px;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
  .carouselContainer {
    flex-direction: column; /* Cambia la disposición a vertical */
    height: 250px; /* Permite que el contenedor crezca con el contenido */
  }

  .carouselContent,
  .imageSection {
    flex: none; /* Desactiva flex para que tome el ancho completo */
    text-align: center; /* Centra el texto */
  }

  .carouselTitle {
    font-size: 1.5rem; /* #E30613uce el tamaño de la fuente para títulos */
  }

  .carouselSubtitle {
    font-size: 0.8rem; /* #E30613uce el tamaño de la fuente para subtítulos */
  }

  .buttonPrimary, .buttonSecondary {
    font-size: 14px; /* #E30613uce el tamaño de la fuente de los botones */
    padding: 8px 16px; /* Ajusta el padding de los botones */
  }

  .imageSection img {
    padding: 0; /* Elimina el padding adicional */
    max-width: 50%;
  }
}

.buttonPrimary, .buttonSecondary {
  padding: 10px 30px;
  border: 2px solid transparent; /* Establece un borde transparente que se hará visible en hover */
  color: white;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  background-color: #E30613; /* Rojo como color de fondo normal */
  transition: background-color 0.3s, border-color 0.3s; /* Añade transición para el color de borde también */
}

.buttonPrimary:hover {
  background-color: #ffff; /* Rojo más oscuro al pasar el mouse */
  border-color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */

}

.buttonSecondary {
  background-color: #E30613;
  margin-left: 10px;
}

.buttonSecondary:hover {
  background-color: #ffff;
  border-color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #E30613; /* Igualmente, haz visible el borde en hover */
}

.highlightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;

}

.highlightImage {
  width: 85%; /* Ajusta esto según el tamaño deseado de la imagen */
  height: auto;
}

.highlightContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}
.highlightContentText {
  width: 50%;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.highlightTitle {
  font-size: clamp(28px, 4vw, 40px);
  margin-bottom: 0.5em;
  color: #595959;
width: 100%;
text-align: left;
}

.highlightDescription {
  font-size: clamp(16px, 4vw, 18px);
  margin-bottom: 1em;
  color: #686868;
  text-align: justify;
  width: 50%;
}

.highlightButton {
  background-color: #e63946; /* o cualquier color que prefieras */
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}

/* Asegúrate de agregar media queries para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 950px) {
  .highlightSection {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    margin: unset;
  }
.highlightDescription{
  width: 100%;
}

  .highlightContent {
    width: 100%;
  }
  .highlightContent {
    padding: 0px;
    flex-direction: column;
    width: 100%;
  }
  .highlightImage{
    text-align: center;
    width: 130%;
  }
  .highlightContentText {

  text-align: center;
  padding-left: 0px;
  }
  
}
@media (max-width: 500px) {
  .highlightImage{
    width: 150%;
  }
}