/* src/components/FloatingButtons/FloatingButtons.module.css */
.container {
  max-width: 13px;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #E30613;
  padding: 15px;
  border-radius: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.buttonIcon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s;
}

.buttonIcon:hover {
  transform: scale(1.1);
}
.buttonIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}