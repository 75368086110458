/* src/components/Navbar/navBar.module.css */
.navbarContentContainer {
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 0 30px; */
  height: 100%;
}

.navbar {
  background-color: #000;
  width: 100%;
  text-align: center;
  position: fixed;
  padding: 0;
  margin: 0;
  /* height: 8%; */
  z-index: 100;
}

.navbarContent {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0;
}

.navbar__logo {
  width: 210px;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navbar__items {
  width: calc(100% - 210px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar__list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar__item {
  margin: 0 1rem;
  /* Ajusta los márgenes como sea necesario */
}

.navbar__link {
  text-decoration: none;
  color: #fff;
  /* El color de tus enlaces, asumiendo que es blanco */
  font-size: 1rem;
  /* Ajusta el tamaño de fuente como sea necesario */
  transition: color 0.3s ease;
  /* Para una transición suave al cambiar de color */
  font-weight: bold;
}

.navbar__link__contact {
  text-decoration: none;
  color: #fec600;
  /* El color de tus enlaces, asumiendo que es blanco */
  font-size: 1rem;
  /* Ajusta el tamaño de fuente como sea necesario */
  transition: color 0.3s ease;
  /* Para una transición suave al cambiar de color */
  font-weight: bold;
}

.navbar__link__contact:hover,
.navbar__link__contact:focus,
.navbar__link__contact:active,
.navbar__link:hover,
.navbar__link:focus,
.navbar__link:active {
  color: #e63946;
  /* Cambia al color de hover/focus de tus enlaces */
}

.navbar__social {
  display: flex;
  align-items: center;
  gap: 10px;
}
.navbar__social a {
  width: 20px;
  height: 20px;
}

.navbar__social img {
  height: 20px;
  width: 20px;
}

.hamburger {
  display: none;
  /* Por defecto está oculto */
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
}

.hamburgerIcon {
  width: 30px;
  height: 30px;
}

.navbar__navigation.navbar__expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 1215px) {
  .navbarContentContainer {
    padding: 0px 10px;
  }

  .navbarContent {
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
}

/* @media (max-width: 1100px) {
  .navbar__logo img {
    display: flex;
    height: 45px !important;
  }
} */
/* Estilos para mostrar el botón de hamburguesa en pantallas pequeñas */
@media (max-width: 900px) {}

@media (max-width: 900px) {

  /* .navbar__logo img {
    display: flex;
    height: 55px !important;
  } */
  .navbar__navigation {
    display: none;
  }

  .navbar__navigation.navbar__expanded {
    display: flex;
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .navbar__list {
    padding: 11px;
    gap: 1rem;
    justify-content: end;
    display: flex;
    background-color: #000;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar__link {
    padding: 10px 30px;
    /* Añade suficiente padding para toques fáciles */
    width: 100%;
    text-align: center;
    /* Centra el texto de los enlaces */
  }

  .navbar__link__contact {
    padding: 10px 30px;
    /* Añade suficiente padding para toques fáciles */
    width: 100%;
    text-align: center;
    /* Centra el texto de los enlaces */
  }

  .navbar__social {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .navbarContentContainer {
    justify-content: space-between;
  }
}

@media (max-width: 600px) {
  /* .navbar__logo img {
    width: 82%;
  } */
}