/* src/components/FloatingButtonWhatsApp/FloatingButtonWhatsApp.module.css */
.container {
  position: fixed;
  bottom: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #25D366;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 100;
}

.buttonIcon {
  width: 50px;
  height: 50px;
}
