/* src/components/ServiceSection/ServiceSection.module.css */
/* src/pages/Home/home.module.css */
.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
.mainTitle {
  font-size: 36px; /* Tamaño grande para el título */
  text-align: center; /* Centrado del título */
}

.contentCard{
  display: flex;
  height: 400px;
}
.serviceDisplay {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px 65px 80px 65px;
}
.serviceDisplay:nth-child(odd) {
  background-color: #ffff; /* color de fondo para los elementos pares */
  color: #595959;
}

.serviceDisplay:nth-child(even) {
  background-color: black; /* color de fondo para los elementos impares */
  color: #ffff;
}

.serviceList {
  width: 30%; /* Ancho de la lista de servicios */
  background-color: #f9f9f9; /* Fondo más claro para la lista */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Espacio entre elementos */
  /* padding-bottom: 15px; */
  background-color: white;
  gap: 10px;
  height: 100%; /* Ajusta esta altura según sea necesario */
}
.serviceListContent{
  display: flex;
  flex-direction: column;
  gap: 5px;
overflow: auto;

}

/* Estilizar la barra de desplazamiento para mejorar la apariencia */
.serviceListContent::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.serviceListContent::-webkit-scrollbar-track {
  background: #D9D9D9; /* Color de fondo de la pista de la barra */
}

.serviceListContent::-webkit-scrollbar-thumb {
  background-color: #E30613; /* Color del control deslizante */
}

.serviceListContent::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8; /* Color cuando el mouse está sobre el control deslizante */
}


.serviceTitle {
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px; /* Tamaño de fuente ajustado */
  color: #333; /* Color de texto oscuro */
  background-color: #D9D9D9;
  text-align: center;
}
.serviceDisplay:nth-child(even) .contentCard .serviceList  {
  background-color: black; /* color de fondo para los elementos impares */
  color: white;
}

.serviceDisplay:nth-child(odd) .contentCard .serviceList  {
  background-color: white; /* color de fondo para los elementos pares */
  color: black;
}

.serviceDisplay:nth-child(odd) .contentCard .serviceList .serviceTitle.active {
  background-color: black; /* color de fondo para los elementos impares */
  color: white;
}

.serviceDisplay:nth-child(even) .contentCard .serviceList .serviceTitle.active {
  background-color: white; /* color de fondo para los elementos pares */
  color: black;
}



.serviceDisplay:nth-child(odd) .contentCard .serviceList .serviceTitle {
  background-color: #D9D9D9; /* color de fondo para los elementos impares */
  color: #000000;

}

.serviceDisplay:nth-child(even) .contentCard .serviceList .serviceTitle {
  background-color: #E30613; /* color de fondo para los elementos pares */
  color: #D9D9D9;

}


.serviceDisplay:nth-child(even) .contentCard .serviceDetails  {
  background-color: white; /* color de fondo para los elementos impares */
  color: #000000;

}

.serviceDisplay:nth-child(odd) .contentCard .serviceDetails  {
  background-color: black; /* color de fondo para los elementos pares */
  color: white;

}

/* .serviceDisplay:nth-child(odd) .contentCard .serviceDetails::-webkit-scrollbar-track  {
  background: black;
}

.serviceDisplay:nth-child(even) .contentCard .serviceDetails::-webkit-scrollbar-track  {
  background: #E30613;
} */





.serviceTitle.active {
  background-color: #000;; /* Fondo del ítem activo */
  color: white;
  text-align: center;
}
.detailsContainer {
  display: flex;
  /* gap: 25px; */
  height: 100%;
  padding: 20px 0px 20px 20px; /* Agrega un relleno si es necesario para no pegar el texto a los bordes */
  align-items: center;
}
.detailsContent{
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px; */
  padding-left: 25px;
  padding-right: 5px;
}

/* Estilizar la barra de desplazamiento para mejorar la apariencia */
.serviceDetails::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.serviceDetails::-webkit-scrollbar-track {
  background: #E30613; /* Color de fondo de la pista de la barra */
}

.serviceDetails::-webkit-scrollbar-thumb {
  background-color: #D9D9D9; /* Color del control deslizante */
}

.serviceDetails::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8; /* Color cuando el mouse está sobre el control deslizante */
}

.serviceDetails {
  width: 70%; /* Ancho de los detalles del servicio */
  background-color: #000; /* Fondo negro */
  color: #fff; /* Texto blanco */
  /* padding-bottom: 51px; */
  height: 100%; /* Ajusta esta altura según sea necesario */
  overflow: auto; /* Habilita desplazamiento cuando el contenido excede el área visible */
  text-align: initial;
  /* display: flex;
  align-items: center; */
}
/* .detailsContainerContents{
  padding: 35px;
} */
.serviceDetails h1 {
  font-size: 24px; /* Tamaño del título de los detalles */
  margin-bottom: 15px; /* Margen debajo del título */
}

.serviceDescription {
  font-size: 18px; /* Tamaño del texto de la descripción */
  line-height: 1.5; /* Espaciado de línea */
  text-align: justify;
}

.serviceDetails img {
  max-width: 100%; /* Asegura que la imagen no sobrepase el ancho del contenedor */
  height: auto; /* Altura automática para mantener la proporción */
  width: auto;
  max-height: 300px;
}

.buttonSecondary {
  padding: 10px 30px;
  background-color: #E30613; /* Fondo rojo para el botón */
  color: white; /* Texto blanco */
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px; /* Espacio adicional encima del botón */
}

.navigationButtons {
  display: flex;
  justify-content: center; /* Centra los botones horizontalmente */

}
.navigationButtons img{
  border-style: unset;
  background-color: unset; /* Fondo rojo para el botón */
  max-width: 100%; /* Asegura que la imagen no sobrepase el ancho del contenedor */
  height: auto; /* Altura automática para mantener la proporción */
  width: 35px;
  height: 35px;
}
.navigationButton{
  border-style: unset;
  background-color: unset; /* Fondo rojo para el botón */
  cursor: pointer;
}
@media (max-width: 824px) {
  .serviceDetails {
    /* padding-bottom: 98px; */
  }
  .serviceDisplay {
    padding: 40px 20px 40px 20px;
  }
  .detailsContent{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px; */
    padding-left: 10px;
    padding-right: 5px;
  }
}
@media (max-width: 950px) {
  /* .detailsContainer{
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 5px;
  } */
  .serviceDetails img {
    /* width: 40%; */
    text-align: center;
    text-align: initial;
  }
  .serviceDetails  {
    text-align: center;
  }
  .detailsContent{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px; */
    padding-left: 10px;
    padding-right: 5px;
  }
  .detailsContainer {
    flex-direction: column;
    gap: 25px;
    padding: 20px ; /* Agrega un relleno si es necesario para no pegar el texto a los bordes */
    align-items: center;
  }


}
@media (max-width: 600px) {
  .contentCard{
    flex-direction: column;
      height: 700px;
  }
.serviceList{
  width: 100%;
}
.navigationButtons {
  padding-top: 15px;
}
.detailsContainer{
  padding: 10px;
}

  .serviceDetails img {
    width: 50%;
    text-align: center;

  }
  .serviceDisplay {
    padding: 20px;
  }
  .serviceDetails {
    width: 100%;
    padding: 10px 0px;
    text-align: center;
  }
}






