/* Estilos generales */

.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
/* Estilos generales */
.carouselContainer {
  width: 100%;
  height: 400px; 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.carouselContent {
  color: white;
  flex: 2; /* Ajusta la proporción del texto e imagen */
  padding: 50px; /* Ajusta el padding general */
}

.imageSection {
  flex: 2; /* Proporción ajustada para la imagen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageSection img {
  max-width: 100%;
  height: auto;
}

.carouselTitle {
  font-size: 2rem;
  margin-bottom: 1rem;
  width: 50%;
}

.carouselSubtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  padding-right: 340px;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
  .carouselContainer {
    flex-direction: column; /* Cambia la disposición a vertical */
    height: 250px; /* Permite que el contenedor crezca con el contenido */
  }
  .carouselTitle {
    width: 100%;
  }
  .carouselContent {
    flex: none; /* Desactiva flex para que tome el ancho completo */
    text-align: center; /* Centra el texto */
  }

  .carouselTitle {
    font-size: 1.5rem; /* #E30613uce el tamaño de la fuente para títulos */
  }

  .carouselSubtitle {
    font-size: 0.8rem; /* #E30613uce el tamaño de la fuente para subtítulos */
  }

  .buttonPrimary, .buttonSecondary {
    font-size: 14px; /* #E30613uce el tamaño de la fuente de los botones */
    padding: 8px 16px; /* Ajusta el padding de los botones */
  }
}

.buttonPrimary, .buttonSecondary {
  padding: 10px 30px;
  border: 2px solid transparent; /* Establece un borde transparente que se hará visible en hover */
  color: white;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  background-color: #E30613; /* Rojo como color de fondo normal */
  transition: background-color 0.3s, border-color 0.3s; /* Añade transición para el color de borde también */
}

.buttonPrimary:hover {
  background-color: #ffff; /* Rojo más oscuro al pasar el mouse */
  border-color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */

}

.buttonSecondary {
  background-color: #E30613;
}

.buttonSecondary:hover {
  background-color: #ffff;
  border-color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #E30613; /* Igualmente, haz visible el borde en hover */
}

