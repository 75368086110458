/* src/pages/Home/home.module.css */
.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
.sectionsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px; /* Espacio entre las secciones */
  padding: 20px;
}
.highlightSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.highlightSectionContainer:nth-child(even) {
  background-color: #D9D9D9; /* color de fondo para los elementos pares */
}

.highlightSectionContainer:nth-child(odd) {
  background-color: #ffff; /* color de fondo para los elementos impares */
}

.highlightImage {
  width: 340px; /* Ajusta esto según el tamaño deseado de la imagen */
  height: auto;
  padding: 40px;
}

.highlightContent {
  width: 50%;
  text-align: left;
}

.highlightTitle {
  font-size: 1.5em; /* o el tamaño que prefieras */
  text-align: center;
color: #595959;
}

.highlightDescription {
  color: #686868;
  text-align: justify;
}

.highlightButton {
  background-color: #e63946; /* o cualquier color que prefieras */
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}

/* Asegúrate de agregar media queries para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 768px) {
  .highlightSection {
    flex-direction: column;
    text-align: center;
  }
  .highlightContent {
    width: unset;
    text-align: left;
    padding: unset;
  }
  .highlightImage {
    width: 55%;
  }
  .highlightSectionContainer:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.termsSection {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.termsColumn {
  flex: 1;
  padding: 10px;
  border-left: 1px solid #ccc;
  text-align: center;
}

/* Remueve el borde izquierdo del primer elemento */
.termsColumn:first-child {
  border-left: none;
}

.termsTitle {
  font-weight: bold;
  margin-bottom: 15px;
}

.termsItem {
  margin-bottom: 5px;
}

/* Asegúrate de tener media queries para el diseño responsivo si es necesario */
@media (max-width: 768px) {
  .termsSection {
    flex-direction: column;
  }
  
  .termsColumn {
    border-left: none;
    border-top: 1px solid #ccc;
  }

  .termsColumn:first-child {
    border-top: none;
  }
}