/* src/components/Footer/footer.module.css */
.footerContainer {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000;
  color: #fff;
  gap: 20px;
  margin: 0 auto;
    box-sizing: border-box;
    width: 70%;
    font-size: clamp(11px, 2vw, 14px);
}

.footerText {
  width: 70%;
  font-size: clamp(11px, 2vw, 14px);
}

.socialIcons {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.socialIcon {
  width: 20px; /* O el tamaño que desees para tus iconos */
  height: 20px; /* Mantén la relación de aspecto */
}
.socialIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
.footerContainer{
  gap: 10px;
}
}