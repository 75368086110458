/* src/components/ContactForm/ContactForm.module.css */
.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
.container {
  display: flex;
  align-items: start;

  height: auto;
  padding: 110px 20px;
  justify-content: space-between;
}
.navbar__logo{
  padding-top: 30px;
}
.header {
  text-align: center;
  min-width: 330px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.header h2 {
  color: #595959;
  font-size: clamp(28px, 4vw, 32px);

}

.header p {
  color: #666;
  font-size: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  padding: 20px;
  max-width: 400px;
}

.form input, .form textarea {
  padding: 10px 15px; /* Más espacio interno */
  margin-bottom: 16px; /* Más espacio entre campos */
  border: 1px solid transparent; /* Cambia aquí a transparente */
  border-radius: 10px; /* Bordes menos #E30613ondeados */
  background-color: #F2F2F2; /* Fondo gris inicial */
  font-family: 'Montserrat', sans-serif; /* Fuente Montserrat */
  outline: unset;
}

.form textarea {
  height: 200px; /* Altura fija para el área de texto */
}

/* Cambiar el fondo a blanco cuando el campo tenga foco */
.form input:focus, .form textarea:focus {
  background-color: white; /* Fondo blanco al enfocar */
  border-color: #E30613; /* Cambio de color de borde al enfocar */
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 3px #E30613; /* Sombra de foco más notable */
}

.form button {
  padding: 10px;
  background-color: #E30613;
  color: white;
  border: 2px solid transparent; /* Establece un borde transparente que se hará visible en hover */
  border-radius: 4px;
  cursor: pointer;
  max-width: 40%;
}
.form button:hover {
  background-color: #ffff;
  border-color: #e30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #e30613; /* Igualmente, haz visible el borde en hover */
}

.navbar__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;

}


/* Media Queries para ajustes en dispositivos más pequeños */
@media (max-width: 750px) {
  .container {
    padding: 20px; /* Padding más pequeño para móviles */
    flex-direction: column;
    align-items: center;
  }

  .header {
    padding: 10px;
    max-width: unset;
    width: 100%;
  }

  .form {
    padding: 15px;
  }
  .form button {
    max-width: 100%;
  }
}
