/* Estilos generales */

.containerContentPage{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;   
}
/* Estilos generales */
.carouselContainer {
  width: 100%;
  height: 400px; 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.carouselContent {
  color: white;
  flex: 2; /* Ajusta la proporción del texto e imagen */
  padding: 50px; /* Ajusta el padding general */
}

.imageSection {
  flex: 2; /* Proporción ajustada para la imagen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageSection img {
  max-width: 100%;
  height: auto;
}

.carouselTitle {
  color: white; /* Asegúrate de que el color del texto sea claro para fondos oscuros */
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 1.8); /* Sombra oscura y algo dispersa */
  font-size: 24px;
  max-width: 500px;

}
.highlightTitle {
  font-size: clamp(25px, 4vw, 30px);
  margin-bottom: 0.5em;
  color: #595959;
width: 100%;
text-align: left;
margin-block-start: 0;
margin-block-end: 0;
}


.carouselSubtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  padding-right: 340px;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
  .carouselContainer {
    flex-direction: column; /* Cambia la disposición a vertical */
    height: 250px; /* Permite que el contenedor crezca con el contenido */
  }

  .carouselContent,
  .imageSection {
    flex: none; /* Desactiva flex para que tome el ancho completo */
    text-align: center;

  }

  .carouselTitle {
    font-size: 1.5rem;
    text-align: justify;
    width: 100%;
  }

  .carouselSubtitle {
    font-size: 0.8rem; /* #E30613uce el tamaño de la fuente para subtítulos */
  }

  .buttonPrimary, .buttonSecondary {
    font-size: 14px; /* #E30613uce el tamaño de la fuente de los botones */
    padding: 8px 16px; /* Ajusta el padding de los botones */
  }

  .imageSection img {
    padding: 0; /* Elimina el padding adicional */
    max-width: 50%;
  }
}

.buttonPrimary, .buttonSecondary {
  padding: 10px 30px;
  border: 2px solid transparent; /* Establece un borde transparente que se hará visible en hover */
  color: white;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  background-color: #E30613; /* Rojo como color de fondo normal */
  transition: background-color 0.3s, border-color 0.3s; /* Añade transición para el color de borde también */
}

.buttonPrimary:hover {
  background-color: #ffff; /* Rojo más oscuro al pasar el mouse */
  border-color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */

}

.buttonSecondary {
  background-color: #E30613;
}

.buttonSecondary:hover {
  background-color: #ffff;
  border-color: #E30613; /* Cambia el color del borde a blanco para hacerlo visible */
  color: #E30613; /* Igualmente, haz visible el borde en hover */
}

.highlightSection {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 30px;
  justify-content: space-between;
}
.highlightContentImage{
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
}
.highlightImage {
  width: 100%;
  max-height: 326px;
  max-width: 270px;
}

.highlightContent {
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.highlightDescription {
  /* margin-bottom: 1em; */
  color: #686868;
  text-align: justify;
  padding: 10px 0px 30px 50px;
  font-size: clamp(16px, 4vw, 18px);
max-width: 400px;
}

.highlightButton {
  background-color: #e63946; /* o cualquier color que prefieras */
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}

/* Asegúrate de agregar media queries para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 768px) {

  .highlightTitle {
  text-align: center;
  }
  .highlightDescription {
    padding: 0;
  max-width: 100%;
  }
  .highlightSection {
    flex-direction: column;
    text-align: center;
    padding: 30px 10px;
    align-items: center;

    margin: unset;
  }
  .highlightContent {
    width: 100%;
  }
  .highlightImage{
    width: 100%;
  }
  .highlightContent {
    padding: 10px;
  }
  .highlightSection:nth-child(even) {
    flex-direction: column-reverse;
  }
  .highlightContentImage{
    width: 100%;
    gap: 0px;
  }
}
