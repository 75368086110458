/* section.module.css */

.sectionContainer {
  text-align: center;
  margin: 20px;
  cursor: pointer;
}
.sectionContainer h2{
  color: black;
  font-size: clamp(16px, 3vw, 20px);
}

.imageContainer {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sectionImage {
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.3s ease; /* Transición para la opacidad */
}

.imageContainer:hover .sectionImage {
  opacity: 0.7; /* Opacidad al hacer hover */
}

.imageContainer::before,
.imageContainer::after {
  content: ''; /* Pseudo-elementos para el efecto de undimiento */
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2); /* Color de sombra */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1; /* Coloca los pseudo-elementos detrás de la imagen */
  transition: transform 0.3s ease; /* Transición para el scale */
}

.imageContainer:hover::before {
  transform: scale(0.95); /* Efecto de undimiento */
}

.imageContainer:hover::after {
  transform: scale(0.98); /* Efecto de undimiento */
}
  @media (max-width: 691px) {
  .imageContainer {
    width: 170px; /* Tamaño del círculo */
    height: 170px;
    border-radius: 50%; /* Hace el círculo perfecto */
    overflow: hidden;
    margin: 0 auto 20px; /* Centra la imagen y añade espacio debajo */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }
  @media (max-width: 788px) {
    .sectionContainer {
      margin: 0px;  
    }

  }

  